import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const GeneralitiesSleeve = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Généralités Sleeve" />
        <Typography
          variant="h4"
          color="inherit"
          style={{ margin: "20px 0px 0px" }}
        >
          Sleeve ou Gastrectomie en manchon
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ margin: "8px 0px 20px" }}
        >
          Technique restrictive
        </Typography>
        <Image alt="Sleeve" filename="sleeve.png" />
        <Typography
          variant="caption"
          color="inherit"
          style={{ textAlign: "center", display: "block", marginBottom: 20 }}
        >
          <a href="https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_gastrectomie_080909.pdf">
            <i>
              https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_gastrectomie_080909.pdf
            </i>
          </a>
        </Typography>
        <Typography variant="body1">
          Cette technique consiste à retirer environ les 2/3 de l’estomac (y
          compris le fundus, qui contient les cellules sécrétant la ghréline).
          L’estomac est réduit à un tube vertical et les aliments passent
          rapidement dans le duodénum.
        </Typography>
        <div style={{ width: "100%", marginTop: 20, textAlign: "center" }}>
          <Button href="/generalities">Revenir aux Généralités</Button>
        </div>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default GeneralitiesSleeve
